import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../../core';
import { HomeComponent, PatientAddComponent } from './home';
import { MainComponent } from './main.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaymentPlanComponent } from './patient/payment-plan/payment-plan.component';
import { NoticesComponent } from './notices/notices/notices.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      //  canActivate: [HomeGuard]
      },
      {
        path: 'patient/add',
        component: PatientAddComponent
      },
      {
        path: 'patient/:inquiryId',
        loadChildren: './patient/patient.module#PatientModule'
      },
      {
        path: 'client',
        loadChildren: './client/client.module#ClientModule'
      },
    ]
  },
  {
    path: 'paymentplan', component: PaymentPlanComponent,
    canActivate: [AuthGuard] },
  {
    path: 'messaging',
    loadChildren: './messaging/messaging.module#MessagingModule' },
  {
    path: 'notices', component: NoticesComponent,
    canActivate: [AuthGuard]
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule {}
